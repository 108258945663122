// eslint-disable-next-line import/named
import {_ReactPixi, Container, Sprite, Text} from '@pixi/react';
import {Assets} from 'pixi.js';
import {ReactNode, useRef, useState} from 'react';
import {ISprite, useFlipAnimation} from './TileFlipAnimation';
import {useTileRemoveAnimation} from './TileRemoveAnimation';
import {TILE_BACKGROUND} from '../assetManifest';
import {textStyle} from '../constants';
import {TILE_HEIGHT, TILE_WIDTH} from '../utils/GameBoardUtils';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const fakeTiles: boolean = import.meta.env.VITE_APP__FAKE_TILES === 'true';

interface TileSpriteProps extends Partial<_ReactPixi.ISprite> {
    tileId: string;
    x: number;
    y: number;
    anchor?: number
    cellLocation?: string | undefined;
    tileFaceIsVisible?: boolean;
    isDeleted?: boolean;
    children?: ReactNode;
}

export function TileSprite({tileId, x, y, cellLocation, anchor, tileFaceIsVisible = true, isDeleted = false, children, ...others}: TileSpriteProps) {
    const spriteRef = useRef<ISprite>(null);
    const [isAnimating, setIsAnimating] = useState(false);
    
    // Internally we will use an anchor of 0.5 to support flipping. Update the position coords relative to our internal anchor, taking into account any anchor provided in our props.
    if (typeof anchor !== 'undefined') {
        x = (TILE_WIDTH / 2) + (x + (TILE_WIDTH * anchor));
        y = (TILE_HEIGHT / 2) + (y + (TILE_HEIGHT * anchor));
    }
    
    // For the flip animation
    const initialTextureName = fakeTiles || !tileFaceIsVisible ? TILE_BACKGROUND : `tile_${tileId}`;
    const prevTextureName = initialTextureName;
    const nextTextureName = fakeTiles || tileFaceIsVisible ? `tile_${tileId}` : TILE_BACKGROUND;
    const {childrenVisible} = useFlipAnimation({spriteRef, prevTextureName, nextTextureName, tileFaceIsVisible});

    // For the remove animation
    useTileRemoveAnimation({x, y, cellLocation, spriteRef, isDeleted, setIsAnimating});

    return (
        <Sprite height={TILE_HEIGHT} width={TILE_WIDTH}
            ref={spriteRef}
            anchor={0.5}
            x={isAnimating && spriteRef.current ? spriteRef.current.x : x}
            y={isAnimating && spriteRef.current ? spriteRef.current.y : y}
            texture={Assets.get(initialTextureName)} // Only initally provided but overwritten later on
            {...others}>
            {fakeTiles && tileFaceIsVisible && (
                <Text text={`${tileId}`}
                    style={textStyle({fill: ['#ee4333', '#66527e'], stroke: '#ee0000', letterSpacing: 6})}
                    x={70} y={30}/>
            )}
            <Container visible={childrenVisible}>{children}</Container>
        </Sprite>
    );
}
