import {Ticker} from 'pixi.js';
import {useEffect, useRef} from 'react';

interface AnimationProps {
    animation: (progress: number) => void
    animationTimeMs: number
    check?: () => boolean
    initialisation?: () => void
    cleanup?: () => void
}

export function useAnimation<Type>({animation, animationTimeMs, check, initialisation, cleanup}: AnimationProps, dependancies: Type[]) {
    const startTimeRef = useRef<number>(0);

    useEffect(() => {
        startTimeRef.current = performance.now();
        const ticker = new Ticker();

        // Sanity checks (if there are any)
        if (check?.()) { return; } // Is check failing

        initialisation?.();
        
        const animate = () => {
            const now = performance.now();
            const elapsed = now - startTimeRef.current;
            const progress = Math.min(elapsed / animationTimeMs, 1);

            // most important callback, animation code
            animation(progress);

            if (progress >= 1) { // If animation has finished
                cleanup?.();
                ticker.stop();
            }
        };

        ticker.add(animate);
        ticker.start();

        return () => {
            ticker.stop();
            ticker.remove(animate);
        };
    }, dependancies);
}
