import {PixiRef, Sprite} from '@pixi/react';
import {Assets} from 'pixi.js';
import {useRef} from 'react';
import {useAnimation} from '../hooks/useAnimation';

export type ISprite = PixiRef<typeof Sprite>;

interface FlashSymbolAnimationProps {
    x: number,
    y: number,
    texture: string,
}

export function FlashSymbolAnimation(props: FlashSymbolAnimationProps) {
    const spriteRef = useRef<ISprite>(null);
    const animationTimeMs = 750;

    useAnimation({
        animation: (progress: number) => {
            const scale = 0.5 + progress * 1.0;
            const alpha = 1 - progress;
            if (spriteRef.current) {
                spriteRef.current.scale = {x: scale, y: scale};
                spriteRef.current.alpha = alpha;
            }
        },
        animationTimeMs,
        cleanup: () => {
            if (spriteRef.current) {
                spriteRef.current.visible = false;
            }
        },
    }, []);

    return <Sprite 
        ref={spriteRef} texture={Assets.get(props.texture)}
        x={props.x} y={props.y} anchor={.5} zIndex={2}/>;
}
