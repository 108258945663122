import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';

import {GameCanvasWrapper, TeamvineProvider} from '@glark-newco/game-library';
import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import {signlinesOverlaySupplier} from './game/components/SignlinesOverlaySupplier';
import {signlinesSceneMutator} from './game/components/SignlinesSceneMutator';
import {SignlinesCanvas} from './game/SignlinesCanvas';

createRoot(document.getElementById('root') as HTMLElement).render(
    <StrictMode>
        <TeamvineProvider sceneMutator={signlinesSceneMutator} gameType={'SignLines'}>
            <GameCanvasWrapper gameName={'Signlines'} gameType={'SignLines'} overlaySupplier={signlinesOverlaySupplier}>
                <SignlinesCanvas/>
            </GameCanvasWrapper>
        </TeamvineProvider>
    </StrictMode>,
);
