import {AvatarProps, Sprite} from '@glark-newco/game-library';
import {Box} from '@mantine/core';
import {useCallback, useMemo} from 'react';
import {AvatarInterface, usePlayerAvatars} from '../hooks/usePlayerAvatars';

export function AvatarSprite({avatar}: {avatar: AvatarInterface}) {
    const {build} = usePlayerAvatars();

    const avatarOptions = useMemo(() => {
        if (!avatar) { return; }
        return build(avatar);
    }, [avatar]);

    const AvatarOption = useCallback(({sprite}: {sprite: string | undefined}) => {
        if (!sprite) { return <></>; }
        return <Box
            key={sprite}
            style={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
            }}>
            <Sprite sprite={sprite}/>
        </Box>;
    }, []);
    
    return (
        <Box style={{
            position: 'relative',
            display: 'block',
        }}>
            {avatarOptions && <>
                <AvatarOption sprite={avatarOptions.Body}/>
                <AvatarOption sprite={avatarOptions.Hair}/>
                <AvatarOption sprite={avatarOptions.Clothing}/>
                <AvatarOption sprite={avatarOptions.Accessory}/>
                <AvatarOption sprite={avatarOptions.Beard}/>
                <AvatarOption sprite={avatarOptions.Glasses}/>
                <AvatarOption sprite={avatarOptions.Hat}/>
            </>}
        </Box>
    );
}

function SignlinesAvatar({playerId}: AvatarProps) {
    const avatar: AvatarInterface = usePlayerAvatars().avatars[playerId];
    return <AvatarSprite avatar={avatar}/>;
}

// A component-provider, not a true react-component, so it can't contain hooks.
export function SignlinesAvatarProvider({playerId}: AvatarProps) {
    return <SignlinesAvatar playerId={playerId} />;
}
