import {randomEnum, useGameServerConnection, usePlayers} from '@glark-newco/game-library';
import {useCallback, useEffect} from 'react';
import {useRecoilValue} from 'recoil';
import {PlayerAvatarChoiceEvent, SignlinesPayload} from '../SignlinesPayloadTypes';
import {playerAvatarStateSelector} from '../state/signlinesGameState';
import {EnumType} from '../utils/Utils';

// Stops multiple avatars being generated due to usePlayerAvatars hook being used in multiple places in the UI
let initialAvatarGenerated: boolean = false;

export enum Body {
    avatar_body_01 = 1,
    avatar_body_02,
    avatar_body_03,
    avatar_body_04,
    avatar_body_05,
    avatar_body_06,
}

export enum Hair {
    none,
    avatar_hair_01,
    avatar_hair_02,
    avatar_hair_03,
    avatar_hair_04,
    avatar_hair_05,
    avatar_hair_06,
    avatar_hair_07,
    avatar_hair_08,
    avatar_hair_09,
    avatar_hair_10,
    avatar_hair_11,
    avatar_hair_12,
    avatar_hair_13,
    avatar_hair_14,
    avatar_hair_15,
    avatar_hair_16,
    avatar_hair_17,
    avatar_hair_18,
    avatar_hair_19,
    avatar_hair_20,
    avatar_hair_21,
    avatar_hair_22,
}


export enum Clothing {
    avatar_clothing_01 = 1,
    avatar_clothing_02,
    avatar_clothing_03,
    avatar_clothing_04,
    avatar_clothing_05,
    avatar_clothing_06,
    avatar_clothing_07,
    avatar_clothing_08,
}

export enum Beard {
    none,
    avatar_beard_01,
    avatar_beard_02,
    avatar_beard_03,
    avatar_beard_04,
    avatar_beard_05,
    avatar_beard_06,
    avatar_beard_07,
}

export enum Accessory {
    none,
    avatar_accessory_01,
    avatar_accessory_02,
    avatar_accessory_03,
    avatar_accessory_04,
    avatar_accessory_05,
    avatar_accessory_06,
    avatar_accessory_07,
    avatar_accessory_08,
    avatar_accessory_09,
    avatar_accessory_10,
}

export enum Glasses {
    none,
    avatar_glasses_01,
    avatar_glasses_02,
    avatar_glasses_03,
    avatar_glasses_04,
    avatar_glasses_05,
    avatar_glasses_06,
    avatar_glasses_07,
    avatar_glasses_08,
    avatar_glasses_09,
    avatar_glasses_10,
    avatar_glasses_11,
}

export enum Hat {
    none,
    avatar_hat_01,
    avatar_hat_02,
    avatar_hat_03,
    avatar_hat_04,
    avatar_hat_05,
    avatar_hat_06,
    avatar_hat_07,
    avatar_hat_08,
}

export type AvatarOptions = 'Body' | 'Hair' | 'Clothing' | 'Beard' | 'Accessory' | 'Glasses' | 'Hat';

export type AvatarInterface = {
    [K in AvatarOptions]: number;
};

export type Avatar = {
    [K in AvatarOptions]: string | undefined;
};

export const categories: Record<string, EnumType>  = {Body, Hair, Clothing, Beard, Accessory, Glasses, Hat};

// Avatar can't be null, so this is the default
export const defaultAvatar: AvatarInterface = {
    Body: 1,
    Hair: 0,
    Clothing: 1,
    Beard: 0,
    Accessory: 0,
    Glasses: 0,
    Hat : 0,
};


export const generateRandomAvatar = () => {
    const randomAvatar = {
        Body: randomEnum(Body) as number,
        Hair: randomEnum(Hair) as number,
        Clothing: randomEnum(Clothing) as number,
        Beard: randomEnum(Beard) as number,
        Accessory: randomEnum(Accessory) as number,
        Glasses: randomEnum(Glasses) as number,
        Hat : randomEnum(Hat) as number,
    };
    return randomAvatar;
};


export function usePlayerAvatars(): {
    avatars: {[K in string]: AvatarInterface}
    build: (avatar: AvatarInterface) => Avatar
} {
    const {connectionReady} = useGameServerConnection();
    const {publishPlayerAction} = useGameServerConnection<SignlinesPayload>();
    const {localPlayer} = usePlayers();
    const avatars = useRecoilValue(playerAvatarStateSelector);

    const build = useCallback((avatar: AvatarInterface): Avatar => {
        const getOption = (e: EnumType, option: number) => !option ? undefined : (e)[option] as string;
        return {
            Body: getOption(Body, avatar.Body),
            Hair: getOption(Hair, avatar.Hair),
            Clothing: getOption(Clothing, avatar.Clothing),
            Beard: getOption(Beard, avatar.Beard),
            Accessory: getOption(Accessory, avatar.Accessory),
            Glasses: getOption(Glasses, avatar.Glasses),
            Hat: getOption(Hat, avatar.Hat),
        };
    }, []);

    const generateRandomAvatarEvent = useCallback(() => {
        publishPlayerAction({
            type: 'playerAvatarChoiceEvent',
            avatar: generateRandomAvatar(),
        } as PlayerAvatarChoiceEvent);
    }, [publishPlayerAction]);


    useEffect(() => {
        if (connectionReady && localPlayer && localPlayer.playerId) {
            const localAvatar = avatars[localPlayer.playerId];
            if (!localAvatar && !initialAvatarGenerated) {
                generateRandomAvatarEvent();
                initialAvatarGenerated = true;
            }
        }
    }, [localPlayer, localPlayer.playerId, connectionReady, avatars]);
    
    return {avatars, build};
}