import {PixiRef, Sprite} from '@pixi/react';
import {Assets, Texture} from 'pixi.js';
import {RefObject, useRef, useState} from 'react';
import {useAnimation} from '../hooks/useAnimation';

export type ISprite = PixiRef<typeof Sprite>;

interface FlipAnimationProps {
    spriteRef: RefObject<ISprite>,
    prevTextureName: string,
    nextTextureName: string,
    tileFaceIsVisible: boolean,
}

export function useFlipAnimation({spriteRef, prevTextureName, nextTextureName, tileFaceIsVisible}: FlipAnimationProps) {
    const [childrenVisible, setChildrenVisible] = useState(true);
    const textureRef = useRef<string>(prevTextureName);
    const animationTimeMs = 350;

    let textureChangedRef = false;

    useAnimation({
        check: () => {
            if (!spriteRef || !spriteRef.current) return true;
            if (textureRef.current === nextTextureName) return true; // Returns when last texture was the samee
            return false;
        },
        initialisation: () => {
            spriteRef.current!.texture = Assets.get(prevTextureName);
            setChildrenVisible(false);
        },
        animation: (progress: number) => {
            const scaleX = Math.abs(Math.cos(progress * Math.PI));
            const scaleY = 1 + 0.5 * Math.sin(progress * Math.PI);
            spriteRef.current!.scale = {x: scaleX, y: scaleY};

            if (progress >= 0.5 && !textureChangedRef) {
                textureChangedRef = true;
                // eslint-disable-next-line
                spriteRef.current!.texture = Assets.get<Texture>(nextTextureName);
            }
        },
        animationTimeMs,
        cleanup: () => {
            spriteRef.current!.scale = {x: 1, y: 1};
            setChildrenVisible(true);
            textureRef.current = nextTextureName;
        },
    }, [tileFaceIsVisible]);

    return {childrenVisible};
}