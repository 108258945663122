import {useGameServerConnection, usePlayers} from '@glark-newco/game-library';
import {BackgroundImage, Box, Button, Text} from '@mantine/core';
import {IconSettingsFilled} from '@tabler/icons-react';
import {useCallback, useMemo} from 'react';
import {useRecoilState} from 'recoil';
import styles from './IdentityCard.module.css';
import {AvatarSprite} from './SignlinesAvatar';
import {AvatarInterface, generateRandomAvatar} from '../hooks/usePlayerAvatars';
import {PlayerAvatarChoiceEvent, SignlinesPayload} from '../SignlinesPayloadTypes';
import {AvatarEditStateAtom} from '../state/AvatarEditAtom';

interface IdentityCardProps {
    playerAvatar: AvatarInterface,
    tempAvatar: AvatarInterface,
    setTempAvatar: React.Dispatch<React.SetStateAction<AvatarInterface>>,
}

export function IdentityCard({tempAvatar, playerAvatar, setTempAvatar}: IdentityCardProps) {
    const [avatarEditState, updateAvatarEditState] = useRecoilState(AvatarEditStateAtom);
    const {publishPlayerAction} = useGameServerConnection<SignlinesPayload>();
    const {localPlayer} = usePlayers();

    const isApplyChangesDisabled = useMemo(() => {
        return JSON.stringify(tempAvatar) === JSON.stringify(playerAvatar);
    }, [tempAvatar, playerAvatar]);

    const handleChange = useCallback(() => {
        updateAvatarEditState({inEdit: !avatarEditState.inEdit});
    }, [avatarEditState.inEdit, updateAvatarEditState]);

    const handleRandom = useCallback(() => {
        setTempAvatar(generateRandomAvatar());
    }, [setTempAvatar]);

    const handleCancel = useCallback(() => {
        setTempAvatar(playerAvatar);
        updateAvatarEditState({inEdit: false});
    }, [setTempAvatar, playerAvatar, updateAvatarEditState]);

    const handleApply = useCallback(() => {
        publishPlayerAction({
            type: 'playerAvatarChoiceEvent',
            avatar: tempAvatar,
        } as PlayerAvatarChoiceEvent);
        updateAvatarEditState({inEdit: false});
    }, [publishPlayerAction, tempAvatar, updateAvatarEditState]);

    return (
        <Box className={styles.identityCard + ' ' + (avatarEditState.inEdit ? '' : styles.center)}
            data-testid={'test-identity-card'}>
            <BackgroundImage
                src='images/Signlines-id-card.png'
                className={styles.backgroundImage}>
                <Box style={{height: '100%', position: 'relative'}}>
                    <Box className={styles.avatarContainer}>
                        <AvatarSprite avatar={tempAvatar}/>
                    </Box>
                    <Text className={styles.playerName} size={'xl'}>{localPlayer.name}</Text>
                    {!avatarEditState.inEdit
                        ?
                        <Button onClick={() => handleChange()} className={styles.changeButton} data-testid="change_btn"
                            rightSection={<IconSettingsFilled width={'1.1rem'} height={'1.1rem'}/>}
                        >
                            Change
                        </Button>
                        : <>
                            <Button onClick={() => handleRandom()} className={styles.randomButton}>Random</Button>
                            <Button onClick={() => handleCancel()} className={styles.cancelButton}>Cancel</Button>
                            <Button onClick={() => handleApply()} className={styles.applyButton}
                                disabled={isApplyChangesDisabled}>
                                Apply
                            </Button>
                        </>}
                </Box>
            </BackgroundImage>
        </Box>
    );
}
