import {GameChatPanel, PlayersPanel, usePlayers} from '@glark-newco/game-library';
import {Stack} from '@mantine/core';
import {useEffect, useMemo, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {CustomiseCharacter} from './CustomiseCharacter';
import {FacilitatorActions} from './FacilitatorActions';
import {IdentityCard} from './IdentityCard';
import {SignlinesAvatarProvider} from './SignlinesAvatar';
import {AvatarInterface, defaultAvatar, usePlayerAvatars} from '../hooks/usePlayerAvatars';
import {AvatarEditStateAtom} from '../state/AvatarEditAtom';


export function LobbyOverlay() {
    const {localPlayer} = usePlayers();
    const avatarEditState = useRecoilValue(AvatarEditStateAtom);
    const [tempAvatar, setTempAvatar] = useState<AvatarInterface>(defaultAvatar);
    const {avatars} = usePlayerAvatars();
    
    const playerAvatar = useMemo(() => avatars[localPlayer.playerId], [avatars, localPlayer, localPlayer.playerId]);

    useEffect(() => {
        if (!localPlayer.playerId) { 
            return;
        }
        const newAvatar = avatars[localPlayer.playerId];
        if (!newAvatar) { 
            return;
        }
        setTempAvatar(newAvatar);
    }, [avatars, localPlayer.playerId, setTempAvatar]);

    return (
        <>
            <PlayersPanel
                avatar={SignlinesAvatarProvider}
                style={{
                    width: '18%',
                    height: '65%',
                    backgroundColor: 'rgba(0,0,0,0.0)',
                    left: '4%',
                    top: '24%',
                }}/>
            {avatarEditState.inEdit
                ? <CustomiseCharacter
                    tempAvatar={tempAvatar}
                    setTempAvatar={setTempAvatar}/>
                : <></>}
            <IdentityCard
                playerAvatar={playerAvatar}
                tempAvatar={tempAvatar}
                setTempAvatar={setTempAvatar}/>
            <Stack gap={0}>
                {localPlayer.isFacilitator &&
                    <FacilitatorActions/>
                }
                <GameChatPanel avatar={SignlinesAvatarProvider} style={{
                    right: '4%',
                    top: '10%',
                    width: '18%',
                    height: '75%',
                    backgroundColor: 'rgba(0,0,0,0)'}}/>
            </Stack>
        </>
    );
}
