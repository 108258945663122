import {PixiRef, Sprite} from '@pixi/react';
import {RefObject, useRef} from 'react';
import {useAnimation} from '../hooks/useAnimation';
import {getRowNumber} from '../utils/GameBoardUtils';


export type ISprite = PixiRef<typeof Sprite>;

interface TileRemoveAnimationProps {
    spriteRef: RefObject<ISprite> | null,
    x: number,
    y: number,
    cellLocation: string | undefined,
    isDeleted: boolean,
    setIsAnimating: (value: boolean) => void
}

export function useTileRemoveAnimation({spriteRef, x, y, cellLocation, isDeleted, setIsAnimating}: TileRemoveAnimationProps) {
    const startPositionRef = useRef({x, y});
    const endPosition = {x: 950, y: 800};
    const rowNumber = cellLocation ? getRowNumber(cellLocation) : 1;

    const diff = {x: endPosition.x - startPositionRef.current.x, y: endPosition.y - startPositionRef.current.y};

    const resetTile = () => {
        spriteRef!.current!.x = startPositionRef.current.x;
        spriteRef!.current!.y = startPositionRef.current.y;
        spriteRef!.current!.visible = true;
        spriteRef!.current!.zIndex = 0;
    };

    useAnimation({
        check: () => {
            if (!isDeleted) { 
                if (spriteRef) {
                    resetTile();
                }
                return true;
            }
            return false;
        },
        animation: (progress: number) => {
            setIsAnimating(true);
            if (spriteRef && spriteRef.current) {
                spriteRef.current.zIndex = 1;
                spriteRef.current.x = startPositionRef.current.x + (diff.x * progress);
                spriteRef.current.y = startPositionRef.current.y + (diff.y * progress);
            }
        },
        animationTimeMs: 450 - ((rowNumber + 1) * 50),
        cleanup: () => {
            if (spriteRef && spriteRef.current) {
                spriteRef.current.visible = false;
            }
            setIsAnimating(false);
        },
    }, [isDeleted]);

    return null;
}