import {InteractionHandler, trunc, useCoordinationRole, useGameServerConnection, usePlayers} from '@glark-newco/game-library';
import {FederatedPointerEvent} from '@pixi/events';
import {Container, Sprite, Text} from '@pixi/react';
import {Sound} from '@pixi/sound';
import {Assets, Texture} from 'pixi.js';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Avatar} from './Avatar';
import {CongratulationsAnimation} from './CongratulationsAnimation';
import {FlashSymbolAnimation} from './FlashSymbolAnimation';
import {GameOverAnimation} from './GameOverAnimation';
import {TileSprite} from './TileSprite';
import {CLOSE_ICON, CROSS, FLIP, INCORRECT, TICK} from '../assetManifest';
import {textStyle} from '../constants';
import {useCrunchTime, useCrunchTimeTransition} from '../hooks/useCrunchTime';
import {useGameOver} from '../hooks/useGameOver';
import {BoardCell, useGameBoard, useGameCompletion} from '../hooks/useSignlinesBoard';
import {RemoveTileEvent, SignlinesPayload} from '../SignlinesPayloadTypes';
import {useTileInteraction} from '../state/TileDraggingState';
import {crunchTimeAnimationDelay} from '../utils/AnimationUtils';
import {
    columnLeftPixels,
    rowTopPixels,
    TILE_HEIGHT,
    TILE_WIDTH,
} from '../utils/GameBoardUtils';

interface PlayedTileProps {
    cell: BoardCell,
    x: number,
    y: number,
    isDeleted: boolean,
    removeTile: () => void,
    tileFaceIsVisible: boolean
}

function PlayedTile({cell, x, y, isDeleted, removeTile, tileFaceIsVisible}: PlayedTileProps) {
    const {setZoom} = useTileInteraction();
    const {players,  localPlayer} = usePlayers();
    const {isGameOver} = useGameOver();
    
    const handleDoubleClick: (event: FederatedPointerEvent) => void = useCallback(() => {
        void setZoom({tileId: cell.tileId!});
    }, [cell.tileId]);

    const interactionHandler = useMemo(() =>
        new InteractionHandler({handleDoubleClick}), []);

    useEffect(() => {
        interactionHandler.reInitialise({handleDoubleClick});
    }, [handleDoubleClick]);

    const author = useMemo(() => {
        return players.find((p) => p.playerId === cell.playerId);
    }, [cell.playerId, players]);

    if (!author)
        return <></>;

    return (
        <>
            <TileSprite
                x={x} y={y} tileId={cell.tileId!} cellLocation={cell.location}
                tileFaceIsVisible={tileFaceIsVisible}
                data-testid={`PlayedTile_${cell.tileId}`}
                anchor={0}
                zIndex={0}
                isDeleted={isDeleted}
                interactive={tileFaceIsVisible}
                pointerdown={(event: FederatedPointerEvent) => interactionHandler.handleMouseDown(event)}
                pointerup={(event: FederatedPointerEvent)  => interactionHandler.handleMouseUp(event)}
                pointerupoutside={(event: FederatedPointerEvent) => interactionHandler.handleMouseUp(event)}
            >
                {!isGameOver && localPlayer.playerId === cell.playerId &&
                    <Sprite
                        texture={Assets.get(CLOSE_ICON)}
                        width={15} height={15}
                        x={TILE_WIDTH / 2 - 25} y={(TILE_HEIGHT / 2 - TILE_HEIGHT) + 10}
                        interactive={true}
                        onclick={removeTile}
                    />
                }
                {!tileFaceIsVisible &&
                    <>
                        <Avatar playerId={author.playerId} x={0} y={-10}/>
                        <Text text={`${trunc(author?.name)}`}
                            scale={0.6}
                            anchor={0.5}
                            style={textStyle({fill: ['#ee4333', '#66527e'], stroke: '#ee0000', letterSpacing: 0})}
                            x={0} y={25}/>
                    </>
                }
            </TileSprite>
        </>
    );
}

function TileLocation({cell}: {cell: BoardCell}) {
    const {publishPlayerAction} = useGameServerConnection<SignlinesPayload>();
    const {localPlayer} = usePlayers();
    const {isCoordinator} = useCoordinationRole();
    const {isCrunchTime, crunchTimeStartTime} = useCrunchTime();
    const initialCrunchTime = useRef<boolean>(isCrunchTime);
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const {isGameOver} = useGameOver();
    
    // position stuff
    const {isDragging, position} = useTileInteraction();
    const x = columnLeftPixels(cell.location.charAt(0));
    const y = rowTopPixels(cell.location.charAt(1));

    // Temporarily cached for when it's removed
    const [tile, setTile] = useState(cell);

    const animationDelay = crunchTimeAnimationDelay(crunchTimeStartTime, cell);
    const renderAnimation = useCrunchTimeTransition(animationDelay);

    const isTileFlipped: () => boolean = () => (isCrunchTime && (renderAnimation || initialCrunchTime.current)) || localPlayer.playerId === cell.playerId || localPlayer.isFacilitator;
    const [tileFaceIsVisible, setTileFaceIsVisible] = useState<boolean>(isTileFlipped());

    const removeTile = useCallback(() => {
        // Remove tile from the gameBoard and return it to the players hand.
        if (!cell.tileId) return; // Shouldn't be possible. Double click?
        if (isGameOver) return; // Also should't be possible. Latency?
        console.log(`${localPlayer.name} has removed the tile ${cell.tileId}.`);
        publishPlayerAction({
            type: 'removeTileEvent',
            tileId: cell.tileId,
            positionId: cell.location,
        } as RemoveTileEvent);
    }, [cell, publishPlayerAction]);

    const beforeIncorrectAnimation = useCallback(() => {
        if (cell.tileId && isCoordinator) {
            removeTile();
        }
        if (cell.tileId) {
            setIsDeleted(true);
        }
        void Assets.get<Sound>(INCORRECT).play();
    }, [cell, setIsDeleted, removeTile, isCoordinator]);

    // Enables us to control when "cell.tileId" is overwritten (so the component doesnt disappear before animating)
    useEffect(() => {
        if (!cell.tileId) { // If tile was removed
            if (isCrunchTime) { // Check if should wait for Crunchtime animation
                setTimeout(() => {
                    setTile(cell);
                }, animationDelay);
            } else {
                setTile(cell);
            }
        } else {
            setTile(cell);
            setIsDeleted(false);
        }
    }, [cell.tileId, cell.playerId]);

    useEffect(() => {
        if (renderAnimation) {
            if (cell.location === cell.tileId) {
                if (!tileFaceIsVisible) {
                    void Assets.get<Sound>(FLIP).play();
                }
            } else {
                beforeIncorrectAnimation();
            }
        }
    }, [renderAnimation]);

    useEffect(() => {
        setTileFaceIsVisible(isTileFlipped());
    }, [isCrunchTime, localPlayer.playerId, cell.playerId, localPlayer.isFacilitator, renderAnimation]);

    const isOver = isDragging && !cell.tileId
            && position.x > x && position.x < (x + TILE_WIDTH)
            && position.y > y && position.y < (y + TILE_HEIGHT);

    return (
        <>
            {tile.tileId && <PlayedTile x={x} y={y} cell={tile} isDeleted={isDeleted} removeTile={removeTile} tileFaceIsVisible={tileFaceIsVisible}/>}
            {/* Animations */}
            {renderAnimation && cell.location === cell.tileId && <FlashSymbolAnimation x={x + TILE_WIDTH / 2} y={y + TILE_HEIGHT / 2} texture={TICK}/>}
            {renderAnimation && cell.location !== cell.tileId && <FlashSymbolAnimation x={x + TILE_WIDTH / 2} y={y + TILE_HEIGHT / 2} texture={CROSS}/>}
            {/* Cell shade when tile is dragged over */}
            {!cell.tileId && <Sprite texture={Texture.WHITE}
                tint={'#000000'}
                alpha={0.0}
                {...(isOver && {alpha: 1})}
                x={x} y={y}
                width={TILE_WIDTH} height={TILE_HEIGHT}
                data-testid={`TileLocation_${cell.location}`}
            />}
        </>
    );
}

export function GameBoard() {
    const gameBoard = useGameBoard();
    const {isComplete} = useGameCompletion();
    const {isGameOver} = useGameOver();

    const boardCells = useMemo(() => {
        return gameBoard.map((cell, idx) =>
            <TileLocation key={idx} cell={cell}/>,
        );
    }, [gameBoard]);

    return (
        <Container sortableChildren>
            {boardCells}
            {isGameOver && isComplete && <CongratulationsAnimation/>}
            {isGameOver && !isComplete && <GameOverAnimation/>}
        </Container>
    );
}