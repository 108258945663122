import {enumValues, Sprite} from '@glark-newco/game-library';
import {Carousel} from '@mantine/carousel';
import {Box, Text} from '@mantine/core';
import {IconBrandRedhat, IconCancel, IconDiamondFilled, IconMan, IconMoodBoy, IconMoustache, IconShirt, IconSunglasses} from '@tabler/icons-react';
import {useMemo, useState} from 'react';
import styles from './CustomiseCharacter.module.css';
import {AvatarInterface, AvatarOptions, categories} from '../hooks/usePlayerAvatars';

const getTrimmedAssetName = (assetName: string): string => {
    return assetName.replace('avatar_', 'avatar_trimmed_');
};

interface AvatarOptionSpriteProps {
    sprite: string,
    style?: object,
    className?: string,
    selectAvatarOption: () => void,
}

function AvatarOptionSprite({sprite, selectAvatarOption, className, style} : AvatarOptionSpriteProps) {
    return (
        <Box style={style}>
            <Sprite
                sprite={sprite}
                className={className}
                onClick={() => selectAvatarOption()}/> 
        </Box>
    );
}

interface AvatarOptionSelectorProps {
    category: number;
    selected: number;
    handleAvatarChange: (c: number, i: number) => void,
}

function AvatarOptionSelector({category, selected, handleAvatarChange}: AvatarOptionSelectorProps) {

    const slides = useMemo(() => {
        const CategoryEnum = categories[Object.keys(categories)[category]];
        const options = enumValues(CategoryEnum);

        return options.sort().map((option: number | string) => {
            return (
                <Carousel.Slide key={option} style={{position: 'relative'}}>
                    {option
                        ? <AvatarOptionSprite
                            sprite={getTrimmedAssetName(CategoryEnum[option] as string)}
                            className={selected === option ? styles.selectedOption : ''}
                            selectAvatarOption={() => handleAvatarChange(category, option as number)}
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '100%',
                                maxWidth: '100%',
                            }}/>
                        : <Box
                            className={selected === 0 ? styles.selectedOption : ''}
                            onClick={() => handleAvatarChange(category, 0)}
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                width: '100%',
                                transform: 'translate(-50%, -50%)',
                            }}>
                            <IconCancel style={{width:'100%', height: '100%', padding: '20%'}}/>
                        </Box>
                    }
                </Carousel.Slide>
            );
        });
    }, [category, selected, handleAvatarChange]);

    return (
        <Carousel
            controlsOffset="xs"
            slideSize="25%"
            slidesToScroll={3}
            slideGap="1%"
            align="start"
            height="100%"
            draggable={true}
            style={{padding: '0 10%', height: '100%'}}
            data-testid={'test-carousel'}
            nextControlIcon={<img src='images/scroll right.png' style={{width: '1.6rem', height: '1.6rem'}} alt={'scroll right'}/>}
            previousControlIcon={<img src='images/scroll left.png' style={{width: '1.6rem', height: '1.6rem'}} alt={'scroll left'}/>}>
            {slides}
        </Carousel>
    );
}

interface CategorySelectorProps {
    categoryIndex: number;
    setCategory: (foo: number) => void, 
}

function CategorySelector({categoryIndex, setCategory}: CategorySelectorProps) {
    const categoriesLength = Object.keys(categories).length;
    const icons = [
        <IconMan style={{width: '2.5rem', height: '2.5rem'}} key={'IconMan'}/>,
        <IconMoodBoy style={{width: '2.5rem', height: '2.5rem'}} key={'IconMoodBoy'}/>,
        <IconShirt style={{width: '2.5rem', height: '2.5rem'}} key={'IconShirt'}/>,
        <IconMoustache style={{width: '2.5rem', height: '2.5rem'}} key={'IconMoustache'}/>,
        <IconDiamondFilled style={{width: '2.5rem', height: '2.5rem'}} key={'IconDiamondFilled'}/>,
        <IconSunglasses style={{width: '2.5rem', height: '2.5rem'}} key={'IconSunglasses'}/>,
        <IconBrandRedhat style={{width: '2.5rem', height: '2.5rem'}} key={'IconBrandRedhat'}/>,
    ];

    const options = Object.keys(categories).map((catagory, i) => (
        <Box
            key={catagory}
            data-testid={`${catagory}_selector`}
            style={{width: `${100 / categoriesLength}%`}}
            className={categoryIndex === i ? styles.selectedCategory : ''}
            onClick={() => setCategory(i)}>
            {icons[i]}
        </Box>
    ));

    return (
        <Box style={{textAlign: 'center'}} data-testid={'test-category-selector'}>
            <Box style={{display: 'flex', justifyContent: 'center', paddingTop: '2%'}}>
                {options.slice(0, 4)}
            </Box>
            <Box style={{display: 'flex', justifyContent: 'center', paddingTop: '2%'}}>
                {options.slice(4, 7)}
            </Box>
        </Box>
    );
}

interface CustomiseCharacterProps {
    tempAvatar: AvatarInterface,
    setTempAvatar: React.Dispatch<React.SetStateAction<AvatarInterface>>,
}

export function CustomiseCharacter({tempAvatar, setTempAvatar}: CustomiseCharacterProps) {
    const [category, setCategory] = useState<number>(0);
    const categoryName = Object.keys(categories)[category] as unknown as AvatarOptions;

    const currentOptionSelection = useMemo(() => {
        return tempAvatar[categoryName];
    }, [tempAvatar, categoryName]);

    const handleAvatarChange = (categoryIndex: number, val: number) => {
        setTempAvatar((prevState: AvatarInterface) => {
            const updatedAvatar = {...prevState} as AvatarInterface;
            const cat = Object.keys(categories)[categoryIndex] as unknown as AvatarOptions;
            updatedAvatar[cat] = val;
            return updatedAvatar;
        });
    };

    return (
        <Box
            style={{
                width: '26%',
                height: '54%',
                backgroundColor: 'rgba(0,0,0,0)',
                pointerEvents: 'initial',
                position: 'absolute',
                right: '50.3%',
                top: '26%',
                display: 'block',
                padding: '3.2% 1.2%',
            }}>
            <Box style={{
                textAlign: 'center',
                marginTop: '2%',
                paddingTop: '1%',
                height: '33%',
            }}>
                <Text style={{
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                }}>Custom Character</Text>
                <CategorySelector categoryIndex={category} setCategory={setCategory}/>
            </Box>
            <Box style={{
                textAlign: 'center',
                borderTop: '1px dashed #b4a17a',
                marginTop: '2%',
                paddingTop: '1%',
                height: '10%',
                position: 'relative',
            }}>
                <Text style={{
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                }}>Select {Object.keys(categories)[category]}</Text>
            </Box>
            <Box 
                style={{height: '57%'}}>
                <AvatarOptionSelector category={category} selected={currentOptionSelection} handleAvatarChange={handleAvatarChange}/>
            </Box>
        </Box>
    );
}