import {Container, Graphics, PixiRef, Sprite} from '@pixi/react';
import {Sound} from '@pixi/sound';
import {Assets} from 'pixi.js';
import {useCallback, useEffect, useRef} from 'react';
import {APPLAUSE, CONFETTI, CONGRATULATIONS, PARTYPOPPER, STREAMERS} from '../assetManifest';
import {useAnimation} from '../hooks/useAnimation';
import {play, radians} from '../utils/AnimationUtils';

export type ISprite = PixiRef<typeof Sprite>;
export type IGraphics = PixiRef<typeof Graphics>;

export function CongratulationsAnimation() {
    const congratulationsSound = useRef(Assets.get<Sound>(APPLAUSE));
    const congratulationsSpriteRef = useRef<ISprite>(null);
    const confettiSpriteRef = useRef<ISprite>(null);
    const leftPartyPopperSpriteRef = useRef<ISprite>(null);
    const rightPartyPopperSpriteRef = useRef<ISprite>(null);
    const leftStreamersSpriteRef = useRef<ISprite>(null);
    const rightStreamersSpriteRef = useRef<ISprite>(null);
    const maskRef = useRef<IGraphics>(null);

    const animationTimeMs = 8000;
    
    useAnimation({
        animation: (progress: number) => {
            // Congratulations
            if (congratulationsSpriteRef.current) {
                play(0, .1875, progress, (value) => congratulationsSpriteRef.current!.scale = {x: value, y: value}, 0, 2.25);
                play(.95, 1, progress, (value) => congratulationsSpriteRef.current!.alpha = value, 1, 0);
                congratulationsSpriteRef.current.visible = true;
            }
            // Streamers
            if (leftStreamersSpriteRef.current) {
                play(.18, .4, progress, (value) => leftStreamersSpriteRef.current!.rotation = value, radians(0), radians(-360));
                play(.18, .4, progress, (value) => leftStreamersSpriteRef.current!.alpha = value, 1, 0);
            }

            if (rightStreamersSpriteRef.current) {
                play(.18, .4, progress, (value) => rightStreamersSpriteRef.current!.rotation = value, radians(0), radians(360));
                play(.18, .4, progress, (value) => rightStreamersSpriteRef.current!.alpha = value, 1, 0);
            }
            // Party poppers
            if (rightPartyPopperSpriteRef.current) {
                play(.2, .5, progress, (value) => rightPartyPopperSpriteRef.current!.rotation = value, radians(180), radians(-180));
                play(.2, .5, progress, (value) => rightPartyPopperSpriteRef.current!.alpha = value, 1, 0);
            }
            if (leftPartyPopperSpriteRef.current) {
                play(.2, .5, progress, (value) => leftPartyPopperSpriteRef.current!.rotation = value, radians(90), radians(450));
                play(.2, .5, progress, (value) => leftPartyPopperSpriteRef.current!.alpha = value, 1, 0);
            }
            // Confetti
            if (confettiSpriteRef.current) {
                play(.2, .35, progress, (value) => confettiSpriteRef.current!.scale = {x: value, y: value}, 0, 2);
                play(.35, .6, progress, (value) => confettiSpriteRef.current!.scale = {x: value, y: value}, 2, 4);
                play(.6, 1, progress, (value) => confettiSpriteRef.current!.scale = {x: value, y: value}, 4, 6);
                play(.275, 1, progress, (value) => confettiSpriteRef.current!.y = value, -100, 500);
                play(.4625, .85, progress, (value) => confettiSpriteRef.current!.alpha = value, 1, 0);
                if (progress > .2) {
                    confettiSpriteRef.current.visible = true;
                }
            }  
        },
        animationTimeMs,
        cleanup: () => {
            if (congratulationsSpriteRef.current) {
                congratulationsSpriteRef.current.visible = false;
            }
        },
    }, []);

    useEffect((): () => void => {
        if (!congratulationsSound.current.isPlaying) {
            void congratulationsSound.current.play();
        }
        return () => congratulationsSound.current.stop();
    }, []);

    useEffect(() => {
        // Fix for mask, still unsure as to why this works
        if (
            leftStreamersSpriteRef.current &&
            rightStreamersSpriteRef.current &&
            leftPartyPopperSpriteRef.current &&
            rightPartyPopperSpriteRef.current &&
            maskRef.current
        ) {
            leftStreamersSpriteRef.current.mask = maskRef.current;
            rightStreamersSpriteRef.current.mask = maskRef.current;
            leftPartyPopperSpriteRef.current.mask = maskRef.current;
            rightPartyPopperSpriteRef.current.mask = maskRef.current;
        }
    }, []);

    return (
        <Container
            x={(1920 / 2)}
            y={(1080 / 2) - 125}
            zIndex={3}
        >
            <Container mask={maskRef.current}>
                {/* Streamers */}
                <Sprite
                    ref={leftStreamersSpriteRef}
                    texture={Assets.get(STREAMERS)}
                    x={-250} y={0}
                    anchor={0.5}
                    mask={maskRef.current}
                    scale={{x: 2.375, y: 2.5}}
                />
                <Sprite
                    ref={rightStreamersSpriteRef}
                    texture={Assets.get(STREAMERS)}
                    x={250} y={0}
                    anchor={0.5}
                    mask={maskRef.current}
                    scale={{x: -2.375, y: 2.5}}
                />
                {/* Party Poppers */}
                <Sprite
                    ref={leftPartyPopperSpriteRef}
                    texture={Assets.get(PARTYPOPPER)}
                    x={-75} y={0}
                    scale={1.5}
                    rotation={radians(90)}
                    mask={maskRef.current}
                    anchor={[0, 1]}
                />
                <Sprite
                    ref={rightPartyPopperSpriteRef}
                    texture={Assets.get(PARTYPOPPER)}
                    x={75} y={0}
                    scale={1.5}
                    rotation={radians(180)}
                    mask={maskRef.current}
                    anchor={[0, 1]}
                />
                <Graphics
                    name="mask"
                    draw={useCallback((g: IGraphics) => {
                        g.clear();
                        g.beginFill(0x000000, 1);
                        g.drawRect(-450, -300, 900, 300);
                        g.endFill();
                    }, [])}
                    ref={maskRef}
                />
            </Container>
            {/* Confetti */}
            <Sprite
                ref={confettiSpriteRef}
                texture={Assets.get(CONFETTI)}
                x={0} y={-100}
                anchor={0.5}
                visible={false}
                scale={{x: 0, y: 0}}
            />
            {/* Congratulations */}
            <Sprite
                ref={congratulationsSpriteRef}
                texture={Assets.get(CONGRATULATIONS)}
                visible={false}
                scale={0}
                x={0} y={0} anchor={0.5}
            />
        </Container>
    );
}
